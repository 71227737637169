<template>
    <v-card v-if="!fetchingCheckListData" flat color="transparent">
        <v-card-title>
            <div class="d-flex align-center">
                <h5 class="secondary--text">Course checklist</h5>
            </div>
        </v-card-title>
        <v-card-text class="pa-0">
            <v-list nav color="transparent">
                <template v-if="!fetchingCheckListData">
                    <v-list-item @click="goToFilesUpload" :two-line="screenSize === 'large' || screenSize === 'small'" :three-line="screenSize === 'medium'">
                        <v-list-item-icon class="mb-0">
                            <v-badge
                                avatar
                                bordered
                                overlap
                                bottom
                                :color="!filesFetching && !uploadedFilesExist ? 'info' : uploadedFilesExist ? 'success' : 'secondary'">
                                <template v-slot:badge>
                                    <v-avatar>
                                        <v-icon v-if="!filesFetching && uploadedFilesExist">done</v-icon>
                                        <v-icon v-else>priority_high</v-icon>
                                    </v-avatar>
                                </template>
                                <v-avatar size="40">
                                    <v-icon large>file_upload</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4 class="secondary--text">Upload files</h4>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!uploadedFilesExist">
                                <span class="caption">No files uploaded yet</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-dialog v-model="inviteDialog" max-width="800">
                        <template v-slot:activator="{ on }">
                            <v-list-item v-on="on" :two-line="screenSize === 'large' || screenSize === 'small'" :three-line="screenSize === 'medium'">
                                <v-list-item-icon class="mb-0 py-1">
                                    <v-badge
                                        avatar
                                        bordered
                                        overlap
                                        bottom
                                        :color="!fetchingInstances && !studentInstancesExists ? 'info' : studentInstancesExists ? 'success' : 'secondary'">
                                        <template v-slot:badge>
                                            <v-avatar>
                                                <v-icon v-if="!fetchingInstances && studentInstancesExists">done</v-icon>
                                                <v-icon v-else>priority_high</v-icon>
                                            </v-avatar>
                                        </template>
                                        <v-avatar size="40">
                                            <v-icon large>person_add</v-icon>
                                        </v-avatar>
                                    </v-badge>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <h4 class="secondary--text">Invite students</h4>
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="!studentInstancesExists">
                                        <span class="caption">No students have joined this course yet</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-card>
                            <v-card-title>
                                <div class="secondary--text">
                                    <v-icon class="mr-1">person_add</v-icon>
                                    Invite students
                                </div>
                            </v-card-title>
                            <v-divider class="mb-1"></v-divider>
                            <v-card-text>
                                <TheSpaceInviteFromSnapshot />
                            </v-card-text>
                        </v-card>
                    </v-dialog>

                    <v-list-item
                        :to="
                            !fetchingSpaceBundles && !distributionBundlesExist
                                ? {
                                      name: 'snapshot-distribute',
                                      params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid }
                                  }
                                : undefined
                        "
                        :two-line="screenSize === 'large' || screenSize === 'small'"
                        :three-line="screenSize === 'medium'">
                        <v-list-item-icon class="mb-0">
                            <v-badge
                                avatar
                                bordered
                                overlap
                                bottom
                                :color="!fetchingSpaceBundles && !distributionBundlesExist ? 'info' : distributionBundlesExist ? 'success' : 'secondary'">
                                <template v-slot:badge>
                                    <v-avatar>
                                        <v-icon v-if="!fetchingSpaceBundles && distributionBundlesExist">done</v-icon>
                                        <v-icon v-else>priority_high</v-icon>
                                    </v-avatar>
                                </template>
                                <v-avatar size="40">
                                    <v-icon large>inventory</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4 class="secondary--text">Distribute material</h4>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!distributionBundlesExist">
                                <span class="caption">No material has been shared with students yet</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-else>
                                <a href="#" @click="openDistributedInstance" style="text-decoration: none" class="caption">Check what has been distributed</a>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        @click="startAssignmentTour"
                        :two-line="screenSize === 'large' || screenSize === 'small'"
                        :three-line="screenSize === 'medium'">
                        <v-list-item-icon class="mb-0">
                            <v-badge
                                avatar
                                bordered
                                overlap
                                bottom
                                :color="!fetchingSpaceBundles && !assignmentsExist ? 'info' : assignmentsExist ? 'success' : 'secondary'">
                                <template v-slot:badge>
                                    <v-avatar>
                                        <v-icon v-if="!fetchingSpaceBundles && assignmentsExist">done</v-icon>
                                        <v-icon v-else>priority_high</v-icon>
                                    </v-avatar>
                                </template>
                                <v-avatar size="40">
                                    <v-icon large>assignment</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4 class="secondary--text">Create assignment</h4>
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="!assignmentsExist">
                                <span class="caption">No assignments have been created yet</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <!-- manage startup schedule -->
                    <v-list-item
                        v-if="currentSpaceType === spaceTypes.EDUCATION_SPACE"
                        :to="{
                            name: 'snapshot-applications'
                        }"
                        :two-line="screenSize === 'large' || screenSize === 'small'"
                        :three-line="screenSize === 'medium'">
                        <v-list-item-icon class="mb-0">
                            <v-badge
                                avatar
                                bordered
                                overlap
                                bottom
                                :color="!fetchingSpaceBundles && !assignmentsExist ? 'info' : assignmentsExist ? 'success' : 'secondary'">
                                <template v-slot:badge>
                                    <v-avatar>
                                        <v-icon v-if="schedules.length > 0">done</v-icon>
                                        <v-icon v-else>priority_high</v-icon>
                                    </v-avatar>
                                </template>
                                <v-avatar size="40">
                                    <v-icon large>schedule_send</v-icon>
                                </v-avatar>
                            </v-badge>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                <h4 class="secondary--text">{{ schedules.length === 0 ? 'Add application startup schedule' : 'Next prestart time' }}</h4>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                <span v-if="schedules.length === 0" class="caption">Define class times when applications are prestarted</span>
                                <span v-else class="caption">{{ schedules[0].startup_time | dateTimeToHuman }} - {{ schedules[0].long_id }}</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <div v-else>
                    <div
                        v-for="item in [
                            { id: 'c1', opacity: 1 },
                            { id: 'c2', opacity: 0.75 },
                            { id: 'c3', opacity: 0.5 },
                            { id: 'c4', opacity: 0.4 }
                        ]"
                        :key="item.id"
                        class="my-6">
                        <div :style="{ opacity: item.opacity }">
                            <v-skeleton-loader type="list-item-avatar-two-line" />
                        </div>
                    </div>
                </div>
            </v-list>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
import eventBus from '@/components/EventBus'

import { breadcrumbs } from '@/mixins/breadcrumbs'
import { assignmentTour } from '@/mixins/assignmentTour'
const TheSpaceInviteFromSnapshot = () => import('@/modules/space/components/TheSpaceInviteFromSnapshot')

export default {
    mixins: [enumsData, breadcrumbs, assignmentTour],
    components: {
        TheSpaceInviteFromSnapshot
    },
    props: {
        schedules: Array
    },
    data() {
        return {
            inviteDialog: false
        }
    },
    methods: {
        goToFilesUpload() {
            eventBus.$emit('openFileUploadDialog', true)
            this.$router.push({
                name: 'snapshot-files',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    fileArea: this.fileAreaType,
                    localPath: this.currentFileLocalPath
                }
            })
        },
        openDistributedInstance() {
            this.fetchDistributedInstanceSnapshots(this.distributedInstance.iid, 'snapshot-files', { fileArea: this.folderTypesLabels.WORKSPACE_FILES })
        }
    },
    mounted() {
        this.$emit('mounted')
    },
    computed: {
        ...mapGetters('snapshotStore', ['currentFileLocalPath']),
        ...mapState('snapshotStore', ['fileAreaType', 'currentFiles', 'filesFetching']),
        ...mapState('spaceStore', ['spaceInstances', 'spaceBundles', 'fetchingSpaceBundles', 'fetchingInstances']),
        ...mapGetters('spaceStore', ['assignmentsExist', 'currentSpaceType']),
        fetchingCheckListData() {
            return this.filesFetching || this.fetchingSpaceBundles || this.fetchingInstances
        },
        distributedInstance() {
            const distributedInstance = this.spaceInstances.find(instance => instance.long_id === this.instanceFixedNames.DISTRIBUTED_INSTANCE)
            return distributedInstance
        },
        screenSize() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return 'small'
            } else if (this.$vuetify.breakpoint.mdAndDown) {
                return 'medium'
            } else if (this.$vuetify.breakpoint.lgAndup) {
                return 'large'
            }
            return 'large'
        },
        studentInstancesExists() {
            if (this.spaceInstances.length && this.spaceInstances.length > 2) {
                return true
            }
            return false
        },
        uploadedFilesExist() {
            const readmeFile = this.currentFiles.filter(f => f.short_id && f.short_id.toLowerCase() === 'readme.md')
            if (!this.currentFiles.length || (readmeFile.length && this.currentFiles.length === readmeFile.length)) {
                return false
            }
            return true
        },
        distributionBundlesExist() {
            const distributionBundles = this.spaceBundles.filter(bundle => bundle.bundle_type_name === this.distributionReasons.MATERIAL_SHARING)
            if (distributionBundles.length) {
                return true
            }
            return false
        }
    }
}
</script>
